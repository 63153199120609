import type { RouteLocationRaw } from '#vue-router';
import { isKeyOfObject } from './typescript';
import { isAfter, isBefore } from 'date-fns';

// recursively remove properties that are null/empty (including arrays and objects)
export function removeEmptiesFromObject(obj: Record<string, any>) {
    // iterate over entries
    Object.entries(obj).forEach(([key, value]) => {
        // If value is a non-empty object or non-empty array
        if (
            value &&
            typeof value === 'object' &&
            Object.entries(value).length
        ) {
            // recursive
            removeEmptiesFromObject(value);
        }

        // If value is an empty object or empty array or non-existant val
        if (
            (value &&
                typeof value === 'object' &&
                !Object.keys(value).length) ||
            value === null ||
            value === undefined ||
            value.length === 0
        ) {
            // remove from array
            if (Array.isArray(obj)) obj.splice(parseInt(key), 1);
            // dates are strange, save them, delete everything else
            else if (!(value instanceof Date)) delete obj[key];
        }
    });
}

export const matchCarByKeywords = (
    car: InventoryV2SearchVehicle,
    keywords: string[]
) => {
    const keys = Object.keys(car);
    const allKeywordsHaveMatch = keywords.every((keyword) => {
        const thisKeywordHasMatch = keys.some((key) => {
            if (isKeyOfObject(key, car)) {
                let value = car[key];
                if (
                    value !== null &&
                    value !== undefined &&
                    key !== 'photoPath' &&
                    key !== 'colorInt'
                ) {
                    value = value.toString().toUpperCase();
                    if (value.includes(keyword.toUpperCase())) return true;
                }
            }
            return false;
        });
        return thisKeywordHasMatch;
    });
    return allKeywordsHaveMatch;
};

export const isExternalLink = (chapmanLink: ChapmanLink) => {
    return (
        typeof chapmanLink.link === 'string' &&
        chapmanLink.link.includes('http')
    );
};

export const hasHiddenRoutes = (
    hiddenRoutes: RouteLocationRaw[],
    route: ReturnType<typeof useRoute>
) => {
    return hiddenRoutes.some((hiddenRoute) => {
        if (typeof hiddenRoute === 'string') {
            return route.path.includes(hiddenRoute);
        }

        if ('name' in hiddenRoute) {
            return route.name === hiddenRoute.name;
        }

        return false;
    });
};

/**
 * This groups assets so that they are ordered in an array by their
 * `order` number.
 *
 * Example:
 * [
 *  // Order `0` assets
 *  {
 *      large: HeroAsset
 *      medium: HeroAsset
 *      small: HeroAsset
 *  }
 *
 *  // Order `1` assets
 *  {
 *      large: HeroAsset
 *      medium: HeroAsset
 *      small: HeroAsset
 *  }
 * ]
 */
export const groupHeros = (
    assets: MarketingAssetsMarketingAssetsManagerAsset[]
) => {
    // Get hero only assets
    const heroAssets = assets.filter((asset) => asset.category === 'hero');

    // Filter out expired heros
    const filteredHeros = heroAssets.filter((asset) => {
        const startDate = asset.startDateTime;
        const endDate = asset.endDateTime;
        if (startDate && !isAfter(new Date(), startDate)) return false;
        if (endDate && !isBefore(new Date(), endDate)) return false;
        return true;
    });

    // If all heros expired, return the most recently expired hero by itself
    if (filteredHeros.length === 0) {
        // Get the most recent hero by sorting it to beginning of array
        const sortedHeros = heroAssets.sort((a, b) => {
            if (!a.endDateTime) return 1;
            if (!b.endDateTime) return -1;

            if (isBefore(a.endDateTime, b.endDateTime)) return 1;
            if (isAfter(a.endDateTime, b.endDateTime)) return -1;
            return 0;
        });

        // Create hero grouping
        const mostRecentHeros = sortedHeros
            .filter((asset) => asset.order === sortedHeros[0].order)
            .reduce(
                (group, asset) => {
                    group[asset.fileName] = asset;
                    return group;
                },
                {} as Record<string, MarketingAssetsMarketingAssetsManagerAsset>
            );

        // Return as array with hero at position 0
        return [mostRecentHeros];
    }

    return filteredHeros
        .reduce(
            (groups, asset) => {
                // Create record if it doesn't exist
                if (asset.order in groups === false) {
                    groups[asset.order] = {};
                }

                groups[asset.order][asset.fileName] = asset;
                return groups;
            },
            [] as Record<string, MarketingAssetsMarketingAssetsManagerAsset>[]
        )
        .filter((groups) => groups); // Remove empty parts of array
};

export const filterAssets = (
    assets: MarketingAssetsMarketingAssetsManagerAsset[],
    category: 'hero' | 'search-card' | 'banner'
) => {
    return assets.filter((asset) => {
        // Check category
        if (asset.category !== category) return false;

        // Check date range
        const startDate = asset.startDateTime;
        const endDate = asset.endDateTime;
        if (startDate && !isAfter(new Date(), startDate)) return false;
        if (endDate && !isBefore(new Date(), endDate)) return false;

        return true;
    });
};
